import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Opening from '../components/Opening'

import doenerdogan from '../assets/images/doener-dogan.jpg'
import pizzadogan from '../assets/images/pizza-dogan.jpg'
// import grilldogan from '../assets/images/grill-dogan.jpg'
import salatdogan from '../assets/images/salata-dogan.jpg'
import schnitzeldogan from '../assets/images/schnitzel.dogan.jpg'
import nudelndogan from '../assets/images/nudeln-dogan.jpg'
// import steakdogan from '../assets/images/steak-dogan.jpg'
import lahmacundogan from '../assets/images/lahmacun-dogan.png'
import hamburgerdogan from '../assets/images/hamburger-dogan.jpg'
import Order from '../components/Order';

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>

                <Banner delivery />

                <div id="main">
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Über Dogan Grillhaus & Pizzeria Flörsheim-Dalsheim und Monsheim</h2>
                            </header>
                                <p>
                                    
                                    Wir von Dogan Döner Pizza Haus Flörsheim-Dalsheim und Monsheim bieten Gerichte von hervorragender Qualität und laden Sie herzlich ein, unsere köstliche Küche kennenzulernen.
                                    Der Schlüssel zu unserem Erfolg ist einfach: Wir bieten hochwertige Gerichte, die stets den Gaumen erfreuen. Wir sind stolz darauf, unseren Kunden schmackhafte und authentische Gerichte zu servieren. zB.: .
                                    Genießen Sie wahre Gaumenfreuden. Wählen Sie dazu ein Getränk aus. Und vor allem: Entspannen Sie sich! Wir bedanken uns herzlich bei Ihnen für Ihre fortwährende Unterstützung.
                                </p>
                                <Order />
                        </div>
                    </section>
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${doenerdogan})`}}>
                            <header className="major">
                                <h3>Döner Gerichte</h3>
                                <p>Saftiges Fleisch</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${pizzadogan})`}}>
                            <header className="major">
                                <h3>Pizza </h3>
                                <p>von Steinoffen</p>
                            </header>
                        </article>
                        {/* <article style={{backgroundImage: `url(${grilldogan})`}}>
                            <header className="major">
                                <h3>Grillspezialitäten </h3>
                                <p>Exquisite <span>Nur in der Filiale Flörsheim-Dalsheim erhältlich</span></p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${steakdogan})`}}>
                            <header className="major">
                                <h3>Steak </h3>
                                <p>Exquisite <span>Nur in der Filiale Flörsheim-Dalsheim erhältlich</span></p>
                            </header>
                        </article> */}
                        <article style={{backgroundImage: `url(${nudelndogan})`}}>
                            <header className="major">
                                <h3>Nudeln </h3>
                                <p>Lecker </p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${salatdogan})`}}>
                            <header className="major">
                                <h3>Salat</h3>
                                <p>knackig, frisch und gesund</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${schnitzeldogan})`}}>
                            <header className="major">
                                <h3>Schnitzel</h3>
                                <p>Alle frisch zubereitet</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${lahmacundogan})`}}>
                            <header className="major">
                                <h3>Lahmacun</h3>
                                <p>MEHR ALS NUR TÜRKISCHE PIZZA</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${hamburgerdogan})`}}>
                            <header className="major">
                                <h3>Hamburger</h3>
                                <p>Saftige Burger</p>
                            </header>
                        </article>
                    </section>
                </div>
                <Opening />

            </Layout>
        )
    }
}

export default HomeIndex